import React, {useState} from 'react';
import Category from './Category';
import './Navigation.css';
import sendGAEvent from "../functions/sendGAEvent";

const Navigation = props => {

  const [active, setActive] = useState("All");
  const [counter, setCounter] = useState(0);
  
  const setCategoryHandler = choise => {
        let currentPlacesState = [];
        let userSession = JSON.parse(localStorage.getItem("user-session"));
        let chosenCategory = "";
        if (choise!=="All") {
           chosenCategory = userSession.message[0].categories.filter( el => choise === el.category_name)
            for (let i = 0; i < chosenCategory[0].places.length; i++) {
                currentPlacesState.push(chosenCategory[0].places[i]);  
                i+1 === chosenCategory[0].places.length && setCounter(i+1);         
            }
             setActive(choise);
             props.setChosenCategory(choise)
             props.setCurrentPlaceList(currentPlacesState);  
        } else {
          for (let i = 0; i < userSession.message[0].allPlaces.length; i++) {
              currentPlacesState.push(userSession.message[0].allPlaces[i]);  
              i+1 === userSession.message[0].allPlaces.length && setCounter(i+1);            
          }
           setActive(choise);
           props.setChosenCategory(choise)
           props.setCurrentPlaceList(currentPlacesState);  
        }
        // const current_session_id = JSON.parse(localStorage.getItem("session_id"));
        sendGAEvent('nav category clicked',{
          cat_name: choise,
          from: "navigation bar",
          page_path: "some test path name"
        })                          
  }
    return (
          <>
           {/* <div className="hotel-img-wrapper">
            
           </div>          */}
              
            <div className="sticky-header">  
              <img className="hotel-img" src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/13/0c/7a/tal-hotel-tel-aviv-an.jpg?w=900&h=-1&s=1" alt="hotel_img" width="100%" height="250px" />
              <h1 className="header-title">Wish List</h1>   
              <ul className="nav-wrapper">                        
                {props.categoriesArray.map( (cat,index) => {
                    return <Category state={active}
                                      key={index} 
                                      catName={cat} 
                                      count={counter}
                                      clicked={setCategoryHandler.bind(this,cat)} />
                  })}
              </ul> 
              <hr className="underline" />
            </div>    
          </>
      );
}

export default Navigation;