import React, {useState} from 'react';
// import Iframe from '../UIElements/Iframe';
import ChosenItemInfo from './ChosenItemInfo';
// import { matterport_link } from "../consts/consts";
import no_image_found from '../images/no_image_found.png';
// import sendGAEvent from "../functions/sendGAEvent";
import Footer from '../Navigation/Footer';
import MatterportModal from '../UIElements/MatterportModal';
import './ChosenItem.css';

const ChosenItem = props => {
 
  let userSession = JSON.parse(localStorage.getItem("user-session"));
  let hotel_coordinates = userSession.message[0].hotel.coordinates;
   
  let hotel_lat = hotel_coordinates.latitude;
  let hotel_long = hotel_coordinates.longitude;
  let place_lat = props.itemDataObject.coordinates.latitude;
  let place_long = props.itemDataObject.coordinates.longitude;
  let user_coordinates = JSON.parse(localStorage.getItem("user-coordinates")); 
  let user_lat = hotel_lat
  let user_long = hotel_long 
  
  if (user_coordinates) {
     user_lat = user_coordinates.latitude;
     user_long = user_coordinates.longitude;
  }

  // const renderMap = () =>  window.open(`https://www.google.co.il/maps/place/ ${props.itemDataObject.coordinates.latitude},${props.itemDataObject.coordinates.longitude}`);

  let mainImg = null;
    if (props.itemDataObject.matterport_id !== null && props.itemDataObject.matterport_id !== "") {
      // mainImg = <Iframe iframe={`${matterport_link}${props.itemDataObject.matterport_id}`} />
      mainImg = <MatterportModal itemDataObject={props.itemDataObject}  />
    }else if(props.itemDataObject.video !== null && props.itemDataObject.video !== "") {
      mainImg = <React.Fragment>
                    <video width="100%" height="100%" controls>
                        <source src={props.itemDataObject.video} type="video/mp4" />
                    </video>
                </React.Fragment>
    }else if(props.itemDataObject.mainImg !== null && props.itemDataObject.mainImg !== ""){
      mainImg = <img src={props.itemDataObject.mainImg} alt="mainImg" width="100%" height="100%" />
    }else {
      mainImg = <img src={no_image_found} alt="not found" width="100%" height="100%" />
    }
  
  return (
    <React.Fragment>    
       <div className="iframe-item__wrapper">
          <div className="iframe-item__content">
             {mainImg}
          </div> 
          <p className={ !props.itemDataObject.matterport_id ? "item-info-title" : "item-info-title-when-3d"}>{props.itemDataObject.name}</p>
       </div>

       
       <div className="item-hash-list">
            {props.itemDataObject.tags.map(tag => {
                return <p className="item-hash"  key={"@@" + tag}># {tag}</p>
              })}
       </div> 

       <div className="place-info">
             <span className="item-info-name">{props.itemDataObject.name}</span>
             <p className="item-info-discription">{props.itemDataObject.description}</p>
       </div>

        <div className="flag-container">
            {/* <p className="contact">Contact {props.itemDataObject.name}</p> */}
            <p className="contact">Contact the place</p>
        </div>  

        <ChosenItemInfo itemDataObject={props.itemDataObject} />

        <div className="flag-container">
            {/* <p className="contact">Navigate to {props.itemDataObject.name}</p> */}
            <p className="contact">Navigate to the place</p>
        </div> 

        <div className="map-container">
          <iframe src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyBCRCHlJsP7etYQcUXUSHQDS7qgNaHBqvM&origin=${user_lat},${user_long}&destination=${place_lat},${place_long}&avoid=tolls|highways&mode=walking&language=en`} />
        </div>                                                                                                                                           

        <Footer />
     </React.Fragment>
    );
} 

export default ChosenItem;

