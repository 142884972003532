import React from 'react';

import './Category.css';

const Category = props => {

   return ( 
       <>
         <li className={props.state === props.catName ? "nav-btn-active" : "nav-btn" } 
           onClick={props.clicked} >{props.catName}          
         </li>
          {/* <span className={props.state === props.catName ? "span-el-active" : "span-el" }>{props.count}</span> */}
       </>
   )
}
export default Category;