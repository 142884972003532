import React from 'react';
import bell from '../images/bell.svg';
import texi from '../images/texi.svg';
import go_back from '../images/go_back.svg';

import './Footer.css';

const Footer = () => {
  let userSession = JSON.parse(localStorage.getItem("user-session"));
  let hotel_phone_number = userSession.message[0].hotel.phone; 
  let taxi_from_hotel_phone = userSession.message[0].hotel.taxi_phone; 
  let hotel_coordinates = userSession.message[0].hotel.coordinates; 
    return (
        <div className="footer-bg">
            <div className="footer-container">
              <div className="footer-item-wrapper">
                <a className="footer-item walk" target="_blank" href={`https://www.google.co.il/maps/place/ ${hotel_coordinates.latitude},${hotel_coordinates.longitude}`} >
                  <img src={go_back} alt="walk" width="25px" height="25px" />
                  <p className="text">Walk back</p>
                </a>
              </div>           
              <div className="footer-item-wrapper">
                <a href={`tel:${taxi_from_hotel_phone}`} className="footer-item texi">
                  <img src={texi} alt="texi" width="35px" height="20px" />
                  <p className="text">Get Texi</p>
                </a>
              </div>
              <div className="footer-item-wrapper">
                <a href={`tel:${hotel_phone_number}`} className="footer-item consierge">
                  <img src={bell} alt="Consierge" width="25px" height="25px" />
                  <p className="text">My Consierge</p>
                </a>
              </div>                           
            </div>
        </div>  
      );
    }

export default Footer;