import React from 'react'

import telephone from '../images/telephone.svg';
import nevigate from '../images/nevigate.svg';
import website from '../images/website.svg';
import get_taxi_green from '../images/get_taxi_green.svg';


import './ChosenItemInfo.css';

const ChosenItemInfo = props => {
    let userSession = JSON.parse(localStorage.getItem("user-session")); 
    let taxi_from_hotel_phone = userSession.message[0].hotel.taxi_phone;
    return (
        <React.Fragment>
            <div className="items-info-list">
              {props.itemDataObject.info.website && 
                <a className="item-info-link" target="_blank" href={`${props.itemDataObject.info.website}`}>
                    <img src={website} alt="website" width="29px" height="29px" />
                </a>}
              {props.itemDataObject.info.phon &&
                <a className="item-info-link" href={`tel:${props.itemDataObject.info.phon}`}>
                    <img src={telephone} alt="telephone" width="29px" height="29px" /> 
                </a>}
              {props.itemDataObject.info.adress &&
                <a className="item-info-link" target="_blank" href={`https://www.google.co.il/maps/place/ ${props.itemDataObject.coordinates.latitude},${props.itemDataObject.coordinates.longitude}`}>
                    <img src={nevigate} alt="nevigate" width="29px" height="29px" />
                </a>}
              {/* {taxi_from_hotel_phone &&  */}
              {
                <a className="item-info-link" href="tel:+972545372999">
                    <img src={get_taxi_green} alt="get_taxi_green" width="35px" height="35px" />
                </a>} 
            </div> 
        </React.Fragment>
       )
      }

export default ChosenItemInfo;

