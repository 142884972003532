import React, {Component} from 'react';
import SplashContent from './SplashContent';
import { url, botPowerKey } from "../consts/consts";
import './withSplashScreen.css';

function LoadingMessage() {

  return (
      <SplashContent />
  );
}

function withSplashScreen(WrappedComponent) {
 
  var pathArray = window.location.pathname.split('/');

  let sessionID = pathArray[1];
  console.log(sessionID);
  // if (!sessionID) {
  //   sessionID = 'jz9F8';
  // }

  localStorage.setItem("session_id", sessionID);

  return class extends Component {
    constructor(props) {
      super(props);
      // console.log("withSplashScreen");
      // console.log(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      try {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,
                      'botPowerKey' : botPowerKey },
            body: JSON.stringify({ session_id: sessionID})
          };
          fetch(`${url}/GetSession`, requestOptions)
          .then( response => response.json())
          .then( answer => {
            console.log(answer)
            localStorage.setItem("user-session", JSON.stringify(answer));
          })
          .catch( err => console.log(err))  

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 4000)



      } catch (err) {
        console.log(err);
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;