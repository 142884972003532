import React, { useState, useEffect } from 'react';
import withSplashScreen from './hoc/withSplashScreen';
import Navigation from './Navigation/Navigation';
import ResultsList from './WishList/ResultsList';
import Footer from './Navigation/Footer';
import ReactGa from 'react-ga';
import InstallingGuideModal from './UIElements/InstallingGuideModal';
import dots from './images/dots.png';
import iphone_shere from './images/iphone_shere.jpg';
import wave from './images/wave.gif';
import opSystem from './functions/opSystem';

import './App.css';

function App() {

  const [category, setCategory] = useState("All");
  const [placeList, setPlaceList] = useState([]);
  const [guideModal, setGuideModal] = useState("false");
  
  useEffect( ()=> {
    let userSession = JSON.parse(localStorage.getItem("user-session"));
    ReactGa.initialize("G-7J00BRDV4Y") 
    ReactGa.pageview(`/${userSession.message[0].session_id}`);
    // console.log("ga - useEffect");
  },[])

  useEffect( ()=> {
    console.log("i go only one time!");
  },[])

  useEffect(()=>{
    let userSession = JSON.parse(localStorage.getItem("user-session"));
    setPlaceList(userSession.message[0].allPlaces);
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var userLat = position.coords.latitude;
        var userLong = position.coords.longitude;
        localStorage.setItem("user-coordinates", JSON.stringify({latitude: userLat,longitude: userLong}));
      });
    } else {
      console.log("Not Available");
    }
  },[])
 
  let userSession = JSON.parse(localStorage.getItem("user-session"));
  let categoriesArray = ["All"];
  
  if (userSession.message[0].categories) {
    userSession.message[0].categories.forEach(element => {
      categoriesArray.push(element.category_name);
    }); 
  }else {
    return <p>Oops.. something went wrong...</p>
  }

  const closeGuideModal = () => setGuideModal(false);

  let system = opSystem();
  let installIcon = system === "Android" 
                     ? 
                    <b>1. Click on the menu button <img src={dots} alt="three dots" height="16px" /> at the top of the page</b>
                     :
                    <b>1. Click on the menu button <img src={iphone_shere} alt="three dots" height="20px" /> of your browser</b>; 
  
 
  let modalInstallGuid = <InstallingGuideModal  
                            show={guideModal} 
                            onCancel={closeGuideModal}>
                              <div className="i-title-container">
                              <img src={wave} alt="wave" width="50px" height="50px" /><h1 className="i-app-title">Hi dear hotel guest</h1>
                              </div>
                              <p className="i-app-content">
                                For a perfect experience please Install the app on your device, you can do it easily and in just two steps:
                                <br/> <br/>
                                {installIcon}
                                <br/> <br/>
                                <b>2. Select from the options in the menu "Add to home screen"</b>
                                <br/> <br/>
                                And ... this is it! The app is installed on your device. Have fun!
                              </p>
                              <p className="i-app-footer">Atlas Hotels & DM-TM Team</p>
                          </InstallingGuideModal>

  if (window.matchMedia('(display-mode: standalone)').matches) {  
    modalInstallGuid = null;
    console.log("yesssssssssssss");
  } 

  return (
    <div className="App">
      {modalInstallGuid}
      <Navigation categoriesArray={categoriesArray} setChosenCategory={setCategory} setCurrentPlaceList={setPlaceList} />
      <ResultsList chosenCategory={category} currentPlaceList={placeList} setList={setPlaceList} />   
      <Footer />
    </div>
  );
}

export default withSplashScreen(App);
