import React from 'react';
import logo_atlas from './logo_atlas.png';
import splash from './splash.gif';
import './SplashContent.css';

const SplashContent = () => {
    return (
        <div className="splash-container">
           <h1 className="system-name">Octopus</h1>
           <h2 className="sub-title-system-name">Presented by</h2>
            <div className="splash-imgs">
              <img src={logo_atlas} alt="loading..." width="100rem" height="100rem" />
              <h3 className="logo-name">ATLAS<br/>HOTELS</h3>
            </div>
            <hr style={{width: "80%"}}/>
            <div className="splash-animation">
              <img src={splash} alt="loading..." width="200rem" height="100rem" />
            </div>          
        </div>
      );
}

export default SplashContent;