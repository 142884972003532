import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { matterport_link } from "../consts/consts";
import Iframe from './Iframe';
import threeD from '../images/threeD.gif';
import telephone from '../images/telephone.svg';
import nevigate from '../images/nevigate.svg';
import './MatterportModal.css';          


      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          bottom: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
        },
      }));
      
const MatterportModal = props => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);
        // const [showMatterportModal, setShowMatterportModal] = useState(false);
              
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {      
          setOpen(false);
        };

        // const closeMatterportModalHandler = () => setShowMatterportModal(false);

      
        const body = (
          <div className={classes.paper}>
             <div className="modal-3d-header">
              <button className="close-3d-modal-btn" onClick={handleClose} >X</button>
              {props.itemDataObject.info.phon &&
                <a className="modal-3d-header-phone" href={`tel:${props.itemDataObject.info.phon}`}>
                    <img src={telephone} alt="telephone" width="28px" height="28px" /> 
                </a>}
              {props.itemDataObject.info.adress &&
                <a className="modal-3d-header-address" target="_blank" href={`https://www.google.co.il/maps/place/ ${props.itemDataObject.coordinates.latitude},${props.itemDataObject.coordinates.longitude}`}>
                    <img src={nevigate} alt="nevigate" width="28px" height="28px" />
                </a>}
            </div>         
            <Iframe iframe={`${matterport_link}${props.itemDataObject.matterport_id}&nt=1&play=1`}  />
          </div>
        );
      
        return (
          <React.Fragment>
            <img onClick={handleOpen} src={threeD} width="100%" height="100%" style={{borderBottom: "1px solid #ccc"}} />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default MatterportModal;


