import React from 'react';

import Avatar from '../UIElements/Avatar';
import Card from '../UIElements/Card';
import phone_red from '../images/phone_red.svg';
import phone_green from '../images/phone_green.svg';
import './ResultItem.css';

const ResultItem = props => {
   let phone = <p style={{color: "#fff"}}>?!?</p>;
   if (props.phone) {
      if (props.status === "Open") {
        phone = <a className="phone-link" href={`tel:${props.phone}`}><img onClick={`tel:${props.phone}`} src={phone_green} alt="phone" width="20px" height="22px" /></a>
      }
      if (props.status === "Close") {
        phone = <a className="phone-link" href={`tel:${props.phone}`}><img onClick={`tel:${props.phone}`} src={phone_red} alt="phone" width="20px" height="22px" /></a>  
      }
       
   }
   
   return (
   <li className="result-item">
     <Card className="result-item__content">
        <div onClick = {props.clicked} className="result-item__image">
            <Avatar image={props.image} alt={props.alt} />
        </div> 
        <div className="result-item__info">
            <div className="result-item-col-info" onClick = {props.clicked}>
               <p className="name">{props.name}</p>
               <p className="description">{props.sub_title}</p>
               <p className="address">{props.address}</p>
            </div>
            <div className="result-item-row-info">
               {props.status ? <p className={props.status === "Open" ? "open" : "close"  } >{props.status}</p> : <p style={{color: "#fff"}}>????</p>}
               {props.distance ? <p className="distance">{`${props.distance}km`}</p> : <p style={{color: "#fff"}}>????</p>}
               {phone}
               {/* {props.phone ? <a className="phone-link" href={`tel:${props.phone}`}><img src={phone_red} alt="phone" width="16px" height="14px" /></a> : <p style={{color: "#fff"}}>????</p>} */}
            </div>  
        </div>
     </Card>
    </li>
   )
}
export default ResultItem;