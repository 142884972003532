import React, { useState } from 'react';
import ResultItem from './ResultItem';
import ItemSideDrawer from './ItemSideDrawer';
import ChosenItem from './ChosenItem';
import './ResultsList.css';
import { Grid } from '@material-ui/core' ;
import { getDistance } from 'geolib';
import { Link, Route } from 'react-router-dom';
// import { useParams } from 'react-router';
import sendGAEvent from "../functions/sendGAEvent";
import no_image_found from '../images/no_image_found.png';


var chosenItemVarible = null;
var userSession = localStorage.getItem("session_id")

const ResultsList = props => {
 
  const [itemDrawerIsOpen, setItemDrawerIsOpen] = useState(false);

  const openItemDrawerHandler = itemData => {
    setItemDrawerIsOpen(true);
    chosenItemVarible = itemData;
    // const current_session_id = JSON.parse(localStorage.getItem("session_id"));
    sendGAEvent('Wish list place clicked',{
      place_name: itemData.name,
      from: "Results list",
      page_path: "some path name"
    })   
  };

  // let params = useParams();
  // if(params.place_id !== null && itemDrawerIsOpen === false)
  // {
  //   let userSession = JSON.parse(localStorage.getItem("user-session"));
  //   let place = userSession.message[0].allPlaces.filter( place => place.place_id === params.place_id);
  //   openItemDrawerHandler(place[0]);
  // } 

  // useEffect(()=>{
  //    console.log("ussEffect");  
  // },[props.chosenCategory])

  var allResults = props.currentPlaceList; 
  console.log(allResults);
                                                                                                                                            
  if(allResults && allResults.length > 0) {
    // calculte OPEN / CLOSE code here 
    let d = new Date();
    let currentDay = d.getDay() // the current day in "0-6" format 
    let hour = d.getHours();
    let min = d.getMinutes();
    (hour >= 0 && hour <= 9 ) && (hour = `0${hour}`);   
    (min < 10) &&  (min = `0${min}`);
    let currentTime = (hour + ":" + min); // hour in "hh:mm" format

    const isInRange = (currentTime, range1, range2, range3 ) => {
       if(currentTime >= range1[0] && currentTime <= range1[1]) 
          return true
       
       if(currentTime >= range2[0] && currentTime <= range2[1]) 
          return true

       if(currentTime >= range3[0] && currentTime <= range3[1]) 
          return true
      
       return false;
    } 

    for (let i = 0; i < allResults.length; i++) {
      if (allResults[i].shifts.length !== 0 && allResults[i].shifts[currentDay]) {
      let range1 = [allResults[i].shifts[currentDay].morning.start,allResults[i].shifts[currentDay].morning.end] 
      let range2 = [allResults[i].shifts[currentDay].noon.start,allResults[i].shifts[currentDay].noon.end] 
      let range3 = [allResults[i].shifts[currentDay].night.start,allResults[i].shifts[currentDay].night.end]       
      isInRange(currentTime, range1 ,range2 ,range3) ? allResults[i].status = "Open" : allResults[i].status = "Close"     
     }
    }

    // calculte distance from Hotel code here 
    let userCoordinates = JSON.parse(localStorage.getItem("user-coordinates")); 
    for (let i = 0; i < allResults.length; i++) {    
        let placeCoordinates = allResults[i].coordinates;
        if (userCoordinates) {
          let distance = getDistance(placeCoordinates,userCoordinates);
          let distanceInKm = (distance / 1000).toFixed(1) 
          allResults[i].distance = distanceInKm;
        }
      } 
    }
  
  const closeItemDrawerHandler = () => {
    let userSession = JSON.parse(localStorage.getItem("user-session"));
    if (props.chosenCategory ==="All") {
      props.setList(userSession.message[0].allPlaces);
      setItemDrawerIsOpen(false); 
   } else {
      let currentPlacesState = [];
      let activeCat = userSession.message[0].categories.filter( el => props.chosenCategory === el.category_name)
       for (let i = 0; i < activeCat[0].places.length; i++) {
           currentPlacesState.push(activeCat[0].places[i]);  
           props.setList(currentPlacesState);
          //  i+1 === activeCat[0].places.length && setCounter(i+1);         
       }
   }
  }


   
  if (allResults.length === 0) {
    return <React.Fragment> 
              <div className="no-found-msg-container">
                <h2 style={{color: "Black", fontSize: "3rem"}}>No Results Found...</h2>
              </div>
            </React.Fragment> 
    }

    return <React.Fragment> 
            <ul className="result-list-normal">
              <Route path={`/${userSession}/:id`}>
                  <ItemSideDrawer show={itemDrawerIsOpen}>
                    {chosenItemVarible && <ChosenItem itemDataObject={chosenItemVarible} 
                                                      closeSideDrawer={closeItemDrawerHandler}
                                                      currentAllRes={allResults} /> }                                                                                                     
                  </ItemSideDrawer>
              </Route>
              
                {/* <Grid container>  */}
                    {allResults.map( result => (
                      // <Grid key={result.place_id} item xs={12}> 
                       <Link key={"@#" + result.place_id} className="item-link-style" to={`/${userSession}/${result.place_id}`}>                                        
                        <ResultItem
                          id={result.place_id}
                          image={result.image!=="" ? result.image : no_image_found}
                          name={result.name}
                          sub_title={result.sub_title}
                          address={result.address}
                          phone={result.info.phon}
                          distance={result.distance}
                          status={result.status}
                          iframe={result.iframe}
                          clicked={openItemDrawerHandler.bind(this,result)}/>    
                        </Link>                       
                      // </Grid>
                     ))}
                {/* </Grid> */}
            </ul>
    </React.Fragment>
}

export default ResultsList;