import React from 'react';

import './Iframe.css';

const Iframe = props => {
  return (
    <div className={`item-iframe ${props.className}`} style={props.style}>
      <iframe
        src={props.iframe}
        style={{ width: props.width, height: props.width }}
        frameBorder="0" 
        allowFullScreen={false}
        allow="vr; xr"
      /> 
    </div>
  );
};

export default Iframe;
